import React from 'react';
import styled from 'styled-components';

const ContactPage = () => {
  return (
    <div
      data-comp={ContactPage.displayName}
    >
      <div>CONTACT</div>
    </div>
  );
};

export default ContactPage;
ContactPage.displayName = 'ContactPage';

const SomeStyle = styled.div`
  color: black;
`